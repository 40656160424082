<template>
  <div>
    <UserPageHeader>
      <v-spacer></v-spacer>

      <v-col md="2" class="d-flex justify-end align-center">
        <v-btn
          xs-small
          icon
          color="gray"
          @click="dialogs.trash = true"
          v-show="isDeleteButtonVisible"
        >
          <v-icon>la-trash-alt</v-icon>
        </v-btn>
      </v-col>
      <v-col md="3" class="d-flex justify-end">
        <InputSearch :placeholder="`Pesquisar em usuários`" v-model="search" />
      </v-col>
    </UserPageHeader>

    <UserList
      :search="search"
      @itemsSelected="(items) => handleSelectItem(items, 2)"
    />

    <AppFloatingButton
      v-show="activeTab != 1"
      @clickFloating="onClickFloatingButton"
      class="top-left-speed-dial floating-button-footer"
    >
      <span></span>
    </AppFloatingButton>

    <ConfirmDeleteDialog
      :ids="idsToDelete"
      :showDialog="dialogs.trash"
      @close="dialogs.trash = false"
    />

    <UserFormDialog
      :showDialog="dialogs.user"
      @close="dialogs.user = false"
    />
  </div>
</template>

<script>
import UserFormDialog from './../forms/UserFormDialog';

import UserList from './../components/UserList';
import UserPageHeader from './../components/UserPageHeader';

import ConfirmDeleteDialog from './../forms/ConfirmDeleteDialog';

export default {
  components: {
    UserPageHeader,
    UserList,
    UserFormDialog,
    ConfirmDeleteDialog,
  },
  data() {
    return {
      search: '',

      idsToDelete: [],
      deleteDialogText: '',

      dialogs: {
        user: false,
        trash: false,
      },

      isDeleteButtonVisible: false,
    };
  },
  methods: {
    onClickFloatingButton() {
      this.dialogs.user = true;
    },

    handleDeleteButtonVisibility(items) {
      if (items.length) {
        this.isDeleteButtonVisible = true;
      } else {
        this.isDeleteButtonVisible = false;
      }
    },

    handleSelectItem(items, index) {
      //console.log(items);
      this.handleDeleteButtonVisibility(items);
      this.idsToDelete = items;
    },
  },
};
</script>
