export default {
  data () {
    return {
      tableConstants: {
        page: 1,
        pageCount: 0,
        itemsPerPage: this.getRowsPerPage(),
        totalVisible: 7,
        reset () {
          this.page = 1;
        }
      }
    };
  },
  watch: {
    tableItemsPerPage(newValue){
      alert(1231);
      // alert(this.$tableItemsPerPage.value);
      this.tableConstants.itemsPerPage = newValue;
    }
  }
};
