export default {
  data() {
    return {
      tableUsers: {
        config: {
          headers: [
            { text: 'Código', value: 'id', width: '10%' },
            { text: 'Nome', value: 'name', width: '30%' },
            { text: 'E-mail', value: 'email', width: '30%' },
            { text: 'Data de criação', value: 'createdAt', width: '30%' },
          ]
        }
      }
    };
  }
};
