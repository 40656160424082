<template>
  <div class="text-center">
    <v-dialog persistent v-model="showDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title
          >Excluir usuário</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-4" text @click="$emit('close')"
            >Cancelar</v-btn
          >
          <ButtonPrimary :disabled="disableButton" @click="onConfirm"
            >Confirmar!</ButtonPrimary
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showNotify" :color="colorMessage">
      {{ message }}
      <v-btn
        :color="colorMessage == 'primary' ? 'white' : 'red'"
        icon
        @click="showNotify = false"
      >
        <v-icon>la la-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('users');
const { mapActions: mapActionsPdv } = createNamespacedHelpers('pdv');

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    ids: {},
  },
  data() {
    return {
      showNotify: false,
      disableButton: false,
      colorMessage: '',
      message: '',
    };
  },
  methods: {
    ...mapActions(['deleteUser']),
    ...mapActionsPdv(['getAllCashBoxes']),
    async onConfirm() {
      try {
        this.disableButton = true;
        
        for (const data of this.ids) {
          const idToDelete = data.id || data;

          await this.deleteUser(idToDelete);
        }

        await this.getAllCashBoxes();

        this.showMessage('Usuário(s) excluído(s) com sucesso!');

        this.disableButton = false;
        this.$emit('close', true);
      } catch (err) {
        let message = '';

        if (err.response && err.response.data && err.response.data.errors)
          message = err.response.data.errors[0].message;
        else message = 'Erro ao excluir';

        this.disableButton = false;
        this.showMessage(message, 'normal');
        this.$emit('close', true);
      }
    },
    showMessage(message, color = 'primary') {
      this.message = message;
      this.colorMessage = color;
      this.showNotify = true;
    },
  },
};
</script>
