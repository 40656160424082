<template>
  <div>
    <v-dialog v-model="showDialog" width="800" @click:outside="$emit('close')">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title
          >{{ title }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="showDialog">
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="user.name"
                label="Nome *"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                hide-details
                v-model="user.email"
                label="Email *"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="user.password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  rules.isLengthValid,
                  rules.hasOneOrMoreNumber,
                  rules.hasOneOrMoreLetters,
                ]"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :loading="isStrengthVisible"
                :label="!user.id ? 'Senha *' : 'Nova senha *'"
              >
                <template v-slot:progress>
                  <v-progress-linear
                    :value="passwordStrength"
                    :color="color"
                    absolute
                    height="5"
                  ></v-progress-linear>
                </template>
              </v-text-field>
              <span v-if="isStrengthVisible">{{ passwordStrengthLabel }}</span>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="confirmPass"
                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.isEqualPasswords]"
                :type="showConfirmPass ? 'text' : 'password'"
                @click:append="showConfirmPass = !showConfirmPass"
                label="Confirmar senha *"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-4" text @click="$emit('close')"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            color="primary"
            large
            :disabled="disableButton || isSaving"
            :loading="isSaving"
            @click="onSubmitForm"
            >Pronto!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar app v-model="showNotify" :color="colorMessage">
      {{ message }}
      <v-btn
        :color="colorMessage == 'primary' ? 'white' : 'red'"
        icon
        @click="showNotify = false"
      >
        <v-icon>la la-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('users');
const { mapActions: mapActionsPdv } = createNamespacedHelpers('pdv');

import axios from 'axios';

export default {
  props: {
    title: {
      type: String,
      default: 'Cadastro de usuário',
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      lineBorderOptions: {
        top: '27px',
        size: '5px',
        height: '25px',
        left: true,
      },
      user: {
        name: '',
        password: '',
        email: '',
      },

      showPass: false,
      showConfirmPass: false,
      confirmPass: '',
      isSaving: false,
      showNotify: false,
      colorMessage: '',
      message: '',

      rules: {
        isLengthValid: (value) => {
          if (value && value.length < 6)
            return 'A senha deve ter pelo menos 6 caracteres';

          return true;
        },
        hasOneOrMoreNumber: (value) => {
          if (!value.match(/[0-9]+/)) return 'Digite pelo menos um número';

          return true;
        },
        hasOneOrMoreLetters: (value) => {
          if (!value.match(/[a-z]+/) && !value.match(/[A-Z]+/))
            return 'Digite pelo menos uma letra';

          return true;
        },
        hasUppercaseAndLowercaseLetters: (value) => {
          if (!value.match(/[a-z]+/) || !value.match(/[A-Z]+/))
            return 'A senha deve ter letras minúsculas e maiúsculas';

          return true;
        },
        isEqualPasswords: (value) => {
          if (value != this.user.password) return 'As senhas não conferem';

          return true;
        },
        hasSpecialChars: (value) => {
          if (!value.match(/[$@#&!]+/))
            return 'A senha deve ter ao menos um caractere especial';
          return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters(['users', 'getUser']),
    passwordStrength() {
      return this.calculateStrength(this.user.password);
    },
    passwordStrengthLabel() {
      if (this.color == 'error') return 'Muito fraca';
      if (this.color == 'warning') return 'Fraca';
      if (this.color == 'extraLightGreen') return 'Forte';

      return 'Muito forte';
    },
    color() {
      if (this.passwordStrength < 40) return 'error';
      if (this.passwordStrength < 60) return 'warning';
      if (this.passwordStrength < 80) return 'extraLightGreen';

      return 'green';
    },
    isStrengthVisible() {
      return this.user.password && this.user.password.length > 0;
    },
  },
  created() {
    if (this.userId) {
      this.user = { ...this.getUser(this.userId) };
    }
  },
  watch: {
    userId(newValue) {
      this.user = {};

      if (newValue) {
        this.user = { ...this.getUser(this.userId) };
      }
    },
  },
  methods: {
    ...mapActions(['createUser', 'editUser']),
    ...mapActionsPdv(['createCashBox']),
    calculateStrength(value) {
      if (!value) return 0;

      let strength = 0;

      if (this.rules.isLengthValid(value) === true) strength += 2;
      if (this.rules.hasOneOrMoreNumber(value) === true) {
        strength += 1;

        if (this.rules.hasUppercaseAndLowercaseLetters(value) === true) {
          strength += 1;
        }
        if (this.rules.hasSpecialChars(value) === true) {
          strength += 2;
        }
      }
      if (this.rules.hasOneOrMoreLetters(value) === true) strength += 1;
      if (this.rules.hasUppercaseAndLowercaseLetters(value) === true) {
        strength += 1;
      }

      if (value.length > 14) {
        strength += 1;
      }

      if (this.rules.hasSpecialChars(value) === true) strength += 2;

      return Math.min(100, strength * 10);
    },
    async onSubmitForm() {
      try {
        if (!this.user.name) throw 'Digite o nome do usuário';
        if (!this.user.email) throw 'Digite o email do usuário';
        if (
          this.passwordStrengthLabel != 'Forte' &&
          this.passwordStrengthLabel != 'Muito forte'
        )
          throw 'A senha deve ser pelo menos forte';

        if (this.user.password != this.confirmPass)
          throw 'As senhas não conferem';

        this.isSaving = true;

        if (this.user.id) {
          await this.editUser({
            user: {
              ...this.user,
            },
          });
        } else {
          const response = await this.createUser({
            user: {
              ...this.user,
            },
          });

          const { data: user } = response;

          await this.createCashBox({
            cashBox: {
              name: 'Caixa - ' + user.name,
              userId: user.id,
            },
          });
        }

        this.showMessage('Usuário salvo');
        this.clearForm();
        this.$emit('close', true);
      } catch (error) {
        const message =
          error.response &&
          error.response.data &&
          Array.isArray(error.response.data.errors)
            ? error.response.data.errors[0].message
            : error;
        this.showMessage(message, 'normal');
      }

      this.isSaving = false;
    },
    clearForm() {
      const user = {
        name: '',
        email: '',
        password: '',
      };

      this.user = { ...user };
      this.confirmPass = '';
    },
    hideNotification() {
      this.showNotify = false;
    },
    showMessage(message, color = 'primary') {
      this.message = message;
      this.colorMessage = color;
      this.showNotify = true;
    },
  },
};
</script>

<style>
.v-input .v-progress-linear {
  border-radius: 20px;
}
</style>
