<template>
  <div>
    <v-data-table
      v-bind="tableUsers.config"
      :page.sync="tableConstants.page"
      :items-per-page="tableConstants.itemsPerPage"
      @page-count="tableConstants.pageCount = $event"
      @click:row="handleClickRow"
      :show-select="true"
      hide-default-footer
      no-data-text="Sem dados"
      no-results-text="Nenhuma categoria encontrada"
      @input="emitSelected"
      class="table-hover-pointer striped"
      :items="users"
      :search="search"
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <template v-slot:item.name="{ item }">
        <span :class="{ 'primary--text': item.isTheLoggedUser }">{{
          item.name
        }}</span>
      </template>
      <template v-slot:item.data-table-select="{ isSelected, item, select }">
        <template v-if="!item.isTheLoggedUser">
          <v-simple-checkbox :value="isSelected" @input="select($event)">{{
            item.name
          }}</v-simple-checkbox>
        </template>
      </template>
    </v-data-table>
    <AppFooter class="footer-paginate">
      <v-col cols="6">
        <v-pagination
          v-model="tableConstants.page"
          :total-visible="tableConstants.totalVisible"
          :length="tableConstants.pageCount"
          color="primary"
        ></v-pagination>
      </v-col>
    </AppFooter>

    <UserFormDialog
      @close="onClose"
      title="Editar usuário"
      :userId="selectedUserId"
      :showDialog="showDialog"
    />
  </div>
</template>

<script>
import tableConstants from '@/utils/mixins/table-constants';
import tableUsers from '../utils/mixins/table-users';

import UserFormDialog from '../forms/UserFormDialog';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('users');

export default {
  mixins: [tableConstants, tableUsers],
  components: {
    UserFormDialog,
  },
  computed: {
    ...mapGetters(['users']),
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      user: {},
      selectedUserId: null,
    };
  },
  methods: {
    onClose(){
      this.showDialog = false;
      this.selectedUserId = null;
    },
    handleClickRow(user) {
      this.selectedUserId = user.id;
      this.showDialog = true;
    },
    emitSelected(event) {
      this.$emit('itemsSelected', event);
    },
  },
};
</script>
